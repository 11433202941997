require('overlayscrollbars/js/jquery.overlayScrollbars.min.js');
require('overlayscrollbars/css/OverlayScrollbars.min.css');

let el = document.createElement('div');
el.setAttribute('ontouchstart', 'return;');
let clickevent = (typeof el.ongesturestart === "function") ? 'touchstart' : 'click';

$(function () {
    let isframe = window.location !== window.parent.location;

    let scrollTo = function (name) {
        let speed = 4;
        let target = $('[data-target="'+name+'"]');
        if (target && target.length > 0) {
            $('html, body').animate({
                scrollTop: target.offset().top
            }, target.offset().top / speed);
            return true;
        }
        return false;
    };

    $(document).on(clickevent, '[data-scroll]', function (e){
        e.preventDefault();
        scrollTo($(this).data('scroll'));
    });

    $(window).on('load', function (){
        if (scrollTo(location.hash.replace('#', ''))) {
            location.hash = '';
        }
    });

    // Async logic
    let load = function (target, after) {
        let url = target.attr('href');

        $('[data-async-items] a').removeClass('current');
        $('[data-async-items] a[href="'+url+'"]').addClass('current');

        let init = Date.now();
        $('body').addClass('loading');

        $.get(url, {}, function (page) {
            let content = $('<div>' + page + '</div>');
            let animation = 250;
            let duration = Date.now() - init;
            $('body').removeClass('menu-opened menu-opened-in');

            setTimeout(function () {
                $('[data-page-content]').html(content.find('[data-page-content]').html());
                window.dispatchEvent(new Event('async'));
                if (after) after(content, url);
                $('body').removeClass('loading');
            }, duration > animation ? 0 : (animation - duration));
        });
    };

    $(document).on(clickevent, '[data-async-items] a, [data-async-menu] .product-items a', function (e){
        e.preventDefault();
        let title = $(this).data('title');
        load($(this), function (content, url) {
            $('title').html(title);
            history.pushState(title, {}, url);
        });
    });

    let URI = location.href.split('#')[0];
    $(window).on('popstate', function () {
        let newURI = location.href.split('#')[0]
        if (newURI === URI) return ;
        URI = newURI;
        let target = $('a[href="'+location.pathname+'"]');
        if (target.length > 0) load(target);
    });

    let open_animation = false;
    $(document).on(clickevent, '[data-menu-opener]', function (e){
        e.preventDefault();
        if (open_animation) return ;
        open_animation = true;
        if ($('body').hasClass('menu-opened')) {
            $('body').removeClass('menu-opened menu-opened-in');
            setTimeout(function () {
                open_animation = false;
            }, 350);
        } else {
            $('body').addClass('menu-opened');
            setTimeout(function () {
                $('body').addClass('menu-opened-in');
                open_animation = false;
            }, 250);
        }
    });

    // $(window).on('load async resize', function (){
    //     $('[data-full-height]').css({ height: $(window).height() });
    // });

    setInterval(function () {
        $('[data-full-height]').css({ height: $(window).height() });
    }, 200);

    $(window).on('load', function (){
        let cookie_key = '[accept.cookie.usage]';

        if (document.cookie.indexOf(cookie_key) === -1 || isframe){
            $('.cookie-block').fadeIn();
            $('.cookie-block .button').on(clickevent, function () {
                if (isframe) return ;
                let date = new Date();
                date.setTime(date.getTime() + (7 * (24 * 60 * 60 * 1000)));
                document.cookie = cookie_key + "=1; expires=" + date.toGMTString() + "; path=/";
                $('.cookie-block').fadeOut(180);
            });
        }
    });

    $(window).on('load', function (){
        return;
        let cookie_key = '[wecan_key.window]';

        if (document.cookie.indexOf(cookie_key) === -1 || isframe){

            let tm = $(window).width() > sizes.M ? 7 : 5;
            if (isframe) tm = 0;
            setTimeout(function () {
                $('.we-can-block').addClass('visible').css({});
                if ($(window).width() < sizes.M) $('body,html').css({ overflow: 'hidden' });
            }, tm * 1000);

            $('.we-can-block .button').on(clickevent, function () {
                if (isframe) return ;
                let date = new Date();
                date.setTime(date.getTime() + ((30 * 60 * 60 * 1000)));
                document.cookie = cookie_key + "=1; expires=" + date.toGMTString() + "; path=/";
                $('.we-can-block').removeClass('visible');
                $('body,html').css({ overflow: '' });
            });
        }
    });

});

let collision = false;
$(document).on('click touchstart', '[data-header-opener]', function (e) {
    console.log(e);
    e.preventDefault();
    let p = $(this).parent();

    if (collision) return ;
    collision = true;

    setTimeout(function () {
        let close = p.hasClass('opened');
        $('[data-header-opener]').parent().removeClass('opened');
        if (close) {
            p.removeClass('opened');
        } else {
            p.addClass('opened');
        }
    }, 150);

    setTimeout(function () {
        collision = false;
    }, 350);
});

let ya = function (tag) {
    let code = $('[data-counters]').html().split('w.yaCounter')[1];
    if (!code) return console.log('yaCounter not found');

    let counter = window['yaCounter' + code.split(' = ')[0] ];
    if (!counter)  return console.log('yaCounter not found');

    counter.reachGoal(tag);
};

$(document).on(clickevent, '[data-goal-details]', function (e) {
    e.preventDefault();

    let tag = $(this).data('goal-details');
    ya('details-' + tag);
    gtag('event', tag , { 'event_category': 'details' });

    let to = $(this).attr('href');
    setTimeout(function () {
        location.href = to;
    }, 350);
});

let voltage_opened = false;

$(document).on(clickevent, '[data-voltage-select] .active', function (e) {
    if (!voltage_opened) {
        $('[data-voltage-select]').addClass('opened');
        setTimeout(function () {
            voltage_opened = true;
        }, 10);
    }
});

$(document).on(clickevent, '*', function (e) {
    if (voltage_opened) {
        $('[data-voltage-select]').removeClass('opened');
        voltage_opened = false;
    }
});

$(document).on(clickevent, '[data-voltage-select] [data-v]', function (e) {
    let sel = $(this).parent();
    sel.find('[data-current]').html( $(this).text() );
    sel.find('[data-v]').removeClass('current');
    $(this).addClass('current');

    let v = $(this).data('v');
    $('[data-voltage]').hide();
    $('[data-voltage="'+v+'"]').show();

});


$(document).on(clickevent, '[data-goal-pdf]', function (e) {
    e.preventDefault();

    let tag = $(this).data('goal-pdf');
    ya('pdf-' + tag);

    gtag('event', tag, {
        'event_category': 'pdf'
    });
    gtag('event', 'conversion', {
        'send_to': 'AW-775793579/9GX_CLqZqJIBEKvX9vEC',
        'value': 10.0,
        'event_callback': function (){}
    });

    let to = $(this).attr('href');
    setTimeout(function () {
        window.open(to, 'Download');
    }, 350);
});


$(document).on('form-return', function () {
    let tag = $('[data-ajax-form] button[type="submit"]').data('goal');

    ya('form-' + tag);
    gtag('event', tag, {
        'event_category': 'form'
    });
    gtag('event', 'conversion', {
        'send_to': 'AW-775793579/MQjRCOeiqJIBEKvX9vEC',
        'value': 100.0
    });
});


let e = $('.product-page .panel-wrapper .panel');
let f = $('.product-footer-wrapper');

$(window).on('load scroll resize', function (){
    if (e.length == 0) return ;
    let need = e.height() + f.height() + 38*2;
    if (need > $(window).height()) {
        let margin = need - $(window).height();
        let bottom = $(document).height() - ($(window).scrollTop() + $(window).height());

        if (bottom < margin) {
            e.css({ marginTop: bottom-margin });
        } else {
            e.css({ marginTop: 0 });
        }
    }
});

$(window).on('load resize', function (){
    $('svg[data-prop]').each(function () {
        let w = $(this).width();
        let min = $(this).data('prop').split(',');
        let max = $(this).data('prop-dx').split(',');
        let k = (min[1] - max[1])/(min[0] - max[0]);
        let r = parseFloat(min[1]) + k * (w - min[0]);
        $(this).css({ top: -parseInt(r) + 'px'});
    });
});


$(window).on('load async', function (){
    $('.form textarea').overlayScrollbars({ sizeAutoCapable: false });

    $('[data-lazy]').each(function() {
        let that = $(this).addClass('blur-animate');
        let img = new Image();
        img.onload = function () {
            that.css({ backgroundImage: 'url("'+that.data('lazy')+'")'});
            return;
            that.css({ backgroundImage: 'url("'+that.data('lazy')+'")'}).removeClass('blur');
            setTimeout(function () {
                that.removeClass('blur-animate');
            }, 500);
        };
        img.src = that.data('lazy');
    });
});
