/**
 *  Можно указать селектор к которому применится класс 'success' после успешной обработки формы
 *  По-умолчанию класс 'success' добавится непосредственно форме
 *
 *  data-success-selector=".success-holder"
 *
 *  <form action="{% url 'namespace:route' %}" method="post" data-ajax-form="ContactForm">
 *      ...
 *  </form>
 */



import { validatorValidateForm, validatorCleanErrors } from '../../components/forms/validation';

$(() => {
    $(document).on('submit', '[data-ajax-form]', function submitAjax(e) {
        e.preventDefault();

        const $form = $(this);
        const classes = $form.data('ajax-form').split(',');
        const successSelector = $form.data('success-selector');
        const $success = successSelector ? $(successSelector) : $form;
        const successTrigger = $form.data('success-trigger');

        if ($form.hasClass('process') || $form.hasClass('success') || $form.hasClass('error')) return ;

        if ($form.find('[type="email"]').val().length < 3) {
            $form.addClass('error');
            $form.find('[type="email"]').focus();
            setTimeout(function () {
                $form.removeClass('error');
            }, 3000);
            return ;
        }

        if ($(window).width() <= window.sizes.M) {
            $('html, body').animate({
                scrollTop: $form.offset().top - 15
            }, 130);
        }

        $form.addClass('process');

        $.ajax({
            url: $form.attr('action'),
            data: $form.serialize(),
            type: $form.attr('method'),
            dataType: 'json',
            success(data) {
                $form.removeClass('process');

                let errorsList = {};
                if (data.errors) {
                    errorsList = data.errors;
                    $form.addClass('error');
                    $form.find('input.required').focus();

                    setTimeout(function(){
                        $form.find('input.required').focus();
                    },0);

                    setTimeout(function () {
                        $form.removeClass('error');
                    }, 5500);

                }
                Object.keys(classes).forEach((i) => {
                    const cls = classes[i];

                    if (errorsList[cls]) {
                        // validatorValidateForm(cls, errorsList[cls]);
                    } else {
                        // validatorCleanErrors(cls);
                    }
                });

                if (data.state === 'success') {

                    $success.addClass('success');
                    if (successTrigger) {
                        $(document).trigger('success');
                    }
                    if ($form.data('goal')) {
                        window.goal($form.data('goal'));
                    }
                    $form.trigger('reset');

                    $(document).trigger('form-return');

                    setTimeout(function () {
                        $form[0].reset();
                        $success.removeClass('success');
                        $form.parents('.wrapper-form').removeClass('form-active');
                    }, 5500);
                }
            },
        });

        return false;
    });
});
/**
 Пример action:

 public function contact()
 {
     if (!$this->request->getIsAjax() || !$this->request->getIsPost()) {
            $this->error(404);
        }
        $form = new RequestForm();
        $data = [
            'state' => 'success'
        ];
        if (!($form->fill($_POST) && $form->valid && $form->send())) {
            $data = [
                'state' => 'error',
                'errors' => [
                    $form->classNameShort() => $form->getErrors()
                ]
            ];
        }
        echo json_encode($data);
 }

 */
