$(function () {
    let circles = $('[data-logo-points] svg .circle');
    if ($(window).width() <= sizes.S) return ;

    let loop = null;
    $('[data-logo-points]').hover(function () {
        loop = setInterval(function () {
            let e = $(circles[ Math.floor(Math.random() * circles.length) ]);
            e.addClass('flash');
            setTimeout(function () {
                e.removeClass('flash');
            }, 100);
        }, 70);
    }, function () {
        clearInterval(loop);
    });
});
