
require('slick-carousel');
require('slick-carousel/slick/slick.css');

// a.href linker
$.fn.link = function (e) {
    return $('[data-target="'+this.data('link')+'"]');
};

$(document).on('mouseover', '[data-link]', function () {
    $(this).link().addClass('hover');
});

$(document).on('mouseout', '[data-link]', function () {
    $(this).link().removeClass('hover');
});

$(document).on('click', '[data-link]', function () {
    if ($('body').hasClass('ready-for-edit')) return;
    let to = $(this).link().attr('href');
    if ($(this).link().attr('target') == '_blank') {
        if (to) window.open($(this).link().attr('href'), 'New')
    } else {
        if (to) location.href = $(this).link().attr('href');
    }
});



$('.slider-array').on('click touchstart', function (e) {
    e.preventDefault();
    let _ = $(this);
    _.addClass('animated');
    setTimeout(function () { _.removeClass('animated'); }, 300);
});

$(function () {
    let current = 0;
    let show = function (i) {
        let cnt = $('[data-slider-block]');
        cnt.children().removeClass('current');
        $.each(cnt, function () {
            $(this).children().eq(i).addClass('current');
        });
        if (location.hash == '' && i == 0) return;

        location.hash = $('[data-anchors] a')[i].dataset['anchor'];

        $('[data-lang-select] a').each(function () {
            this.href = this.href.split('#')[0] + location.hash;
        });
    };

    $('[data-slider-arrow]').on('click touchstart',function (e) {
        e.preventDefault();

        let $this = $(this);
        let direction = $this.data('slider-arrow');
        let cnt = $('[data-slider-block]:first').children();

        if (direction == 'next'){
            current += 1;
            if (current >= cnt.length) current = 0;
            show(current);
        }

        if (direction == 'prev'){
            current -= 1;
            if (current < 0) current = cnt.length - 1;
            show(current);
        }
    });

    $('[data-slider-preview-item]').on('click touchstart',function (e) {
        e.preventDefault();
        current = $(this).parent().index();
        show(current);
    });

    if (location.hash != ''){
        let e = $('[data-anchors] a[data-anchor="'+location.hash.substr(1)+'"]');
        if (e) return show(e.index());
    }

    show(0);
});

$(function () {
    let cnt = $('[data-list-advantages] [data-slick]');

    cnt.slick({
        autoplay: false,
        arrows: false,
        infinite: false,
        centerPadding: '25px',
        centerMode: false,
        variableWidth: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {breakpoint: 3000, settings: "unslick"},
            {breakpoint: sizes.XS, settings: { slidesToShow: 1, slidesToScroll: 1}}
        ]
    }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
        let t =  1 + nextSlide;
        $('[data-slider-start]').text('0'+t);
        let now = (100/count) * (nextSlide+1);
        $('[data-slider-progress-line]').css({
            'width': now+'%'
        });
    });

    let count = $('[data-slick]').find('.slick-track').children().length;
    $('[data-slider-end]').text('0' + count);
    $('[data-slider-progress-line]').css({ width: 25+'%' });

    $('[data-list-advantages] .slider-array').on('click touchstart', function (e) {
        e.preventDefault();
        cnt.slick( $(this).data('action') );
    });
});

$(function () {
    let cnt = $('[data-slick-bottom]');

    cnt.slick({
        autoplay: false,
        arrows: false,
        infinite: false,
        centerPadding: '20px',
        centerMode: false,
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {breakpoint: 3000, settings: "unslick"},
            {breakpoint: sizes.XS, settings: { slidesToShow: 1, slidesToScroll: 1}}
        ]
    });

    $('[data-slick-bottom-arrows] a').on('click touchstart', function (e) {
        e.preventDefault();
        cnt.slick( $(this).data('action') );
    });
});
