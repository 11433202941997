$(window).on('scroll ontouchmove load async', function () {
    let visible = $(window).scrollTop() + $(window).height();

    $('.need-animation').each(function() {
        let e = $(this);
        let delay = e.data('delay') || 250;
        let ratio = e.data('ratio') || 100;

        if (e.offset().top < visible - window.innerHeight/ratio) {
            e.removeClass('need-animation');
            setTimeout(function () {
                e.addClass('animate');
                e.trigger('animation-init');
                // Event usage:
                // $(element).on('animation-init', function(){ /*Class added*/ });
            }, delay);
        }
    });
});
