$(function () {
    if ($(window).width() <= sizes.S) return ;

    let rand = function (v, speed) {
        v += (Math.random() - 0.5) * Math.max(speed, 10) * 4;
        return Math.round(v/10) * 10 + 3;
    };

    $('[data-bg-disable]').hover(function () {
        $('body').addClass('v-disable');
    }, function () {
        $('body').removeClass('v-disable');
    });

    $('[data-dots-background]').each(function () {
        let last = null;
        let that = $(this);

        let permanent = $(this).data('dots-background');
        if (permanent) {
            let x = 100;
            let y = 100;
            if (permanent == 'right') x = that.width() - x - 200;
            if (permanent == 'left-center') y = that.height()/3 - 100;

            let n = 0;
            setInterval(function () {
                let item = $('<i/>').css({
                    left: rand(x + n * 20, 30),
                    top: rand(y, 30),
                }).appendTo(that).addClass('vv');

                setTimeout(function () {
                    item.removeClass('vv');
                }, 100);

                setTimeout(function () {
                    item.remove();
                }, 3200);

                n = (n + 1) % 10;
            }, 300);
        }

        this.onmousemove = function (e) {
            let area = $(this).offset();
            let mouse = [e.pageX - area.left, e.pageY - area.top];
            if (last === null) return (last = mouse);

            let speed = Math.sqrt(Math.pow(last[0] - mouse[0], 2) + Math.pow(last[1] - mouse[1], 2));

            for (let i = speed/10; i > 0; i--) {
                let item = $('<i/>').css({
                    left: rand(mouse[0], speed),
                    top: rand(mouse[1], speed),
                }).appendTo(that).addClass('v');

                setTimeout(function () {
                    item.removeClass('v');
                }, 100);

                setTimeout(function () {
                    item.remove();
                }, 3200);
            }

            last = mouse;
        };
    });
});
