require('../lib/jquery-ui.min.js');

$(window).on('load sorter-init', function (){
    let t = $('[data-editable-on] [data-container]');
    if (t.sortable("instance")) t.sortable('destroy');
    t.sortable({axis: 'y', cancel: '.drag-disable'});
});

$(function () {
    $(document).on('click', '[data-add]', function (){
        let html = $('#'+ $(this).data('add')).html();
        let cnt = $(this).parent();
        if (cnt.hasClass('group')) cnt = cnt.parent();
        cnt.children('[data-container]').append(html);
        $(document).trigger('sorter-init');
    });

    $(document).on('click', '[data-ie-del]', function (){
        $(this).parent().fadeOut(200, function () {
            $(this).remove();
        });
    });

    $(document).on('keydown', function (e){
        if (e.which !== 8 && e.which !== 46) return;
        let t = $(':focus');
        if (t.html() != "" || !t.hasClass('listable')) return ;
        if (t.parent().parent().children().length > 1) t.parent().remove();
    });

    $(document).on('keypress', '[contenteditable="true"]', function (e){
        if (e.which === 13 && $(this).hasClass('listable')){
            let li = $(this).parent().clone();
            li.find('span').html('');
            $(this).parent().after(li);
            li.find('span').focus();
        }
        if ($(this).parent().data('enter') === 'allow') {
            return true;
        }
        return e.which !== 13;
    });

    $(document).on('paste', '[contenteditable="true"]', function(e) {
        //let pd = e.originalEvent.clipboardData.getData('text');
        //document.execCommand("insertHTML", false, pd);
        //e.preventDefault();
    });

    window.addEventListener('saver', function(){
        let spec = [];
        $('[data-spec]').each(function () {
            let child = [];
            $(this).find('[data-spec-child]').each(function () {
                child.push({
                    key: $(this).find('[data-name="child-key"]').html(),
                    val: $(this).find('[data-name="child-val"]').html(),
                });
            });
            spec.push({
                key: $(this).find('[data-name="key"]').html() || '',
                val: $(this).find('[data-name="val"]').html() || '',
                head: $(this).find('[data-name="head"]').html() || '',
                child: child
            });
        });

        let features = [];
        $('[data-feature]').each(function () {
            let content = [];
            $(this).find('[data-name="content"]').each(function () {
                content.push($(this).html());
            });
            features.push({
                type: $(this).data('feature'),
                content: content
            });
        });

        $.post(location.href, { model: { spec: spec, features: features }});
    });
});
