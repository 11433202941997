require('../scss/app.scss');

import sizes from "../scss/_settings/_settings.scss";
window.sizes = sizes;

require('./common/js_validation.js');


require('./parts/main.js');
require('./parts/scroll-animation.js');
require('./parts/logo-points.js');
require('./parts/dots-background.js');
require('./parts/json-editor.js');

require('./pages.js');

require('../fonts/SourceSansPro/face.css');

